<template>
  <div>
    <el-image fit="contain" style="width: 100%;" :src="bannerUrl"></el-image>

    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <li class="tabStyleLi">
            <div class="tabStyle" style="background:#3246F4"></div>
            <div
              class="tabStyle2"
              style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
            >
              <div>01</div>
              <div>公司新闻</div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">首页</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">最新动态</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div style="width:100%; height:900px;margin-top:30px">
      <div style="width:1200px; margin:0 auto;">
        <el-row :gutter="20" style="margin-bottom:30px">
          <router-link :to="{ path: '/components/web/news/case01-1' }">
            <el-col :span="8">
              <el-card
                class="box-card"
                shadow="hover"
                style="text-align:left; cursor: pointer;"
              >
                <div slot="header" class="clearfix">
                  <span style="color:#3246f4!important; "
                    >展望未来，蓝图宏伟</span
                  >
                  <span style="float: right; padding: 3px 0">2021-05-01</span>
                </div>
                <div class="text item" style="min-height:130px;">
                  转变观念，保持奋发图强，一往无前的进取创新精神，努力把公司的发展战略推一个更新的阶段，为创新局面而奋斗不息
                </div>
              </el-card>
            </el-col>
          </router-link>
          <router-link :to="{ path: '/components/web/news/case01-2' }">
            <el-col :span="8">
              <el-card
                class="box-card"
                shadow="hover"
                style="text-align:left; cursor: pointer;"
              >
                <div slot="header" class="clearfix">
                  <span style="color:#3246f4!important; ">企业文化</span>
                  <span style="float: right; padding: 3px 0">2021-06-01</span>
                </div>
                <div class="text item" style="min-height:130px;">
                  <div>为客户-提供满意服务</div>
                  <div>为员工-创造幸福指数</div>
                  <div>为股东-实现信任回报</div>
                  <div>为社会-贡献自身力量</div>
                </div>
              </el-card>
            </el-col>
          </router-link>
        </el-row>
      </div>
    </div>

    <div style="height:30px"></div>
  </div>
</template>

<script>
export default {
  name: 'itc',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/news.jpg'),
      qygsImg: require('../../../assets/aboutus/abuoutus-img1.png'),
    }
  },
  mounted() {
    this.$bus.$emit('getCurNav', 'news')
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 200px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.jjfaTitle {
  height: 20px;
  font-size: 21px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #2c3dd6;
  line-height: 37px;
  margin: 5px 0;
}
.jjfamiaoshu {
  height: 16px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #666666;
  line-height: 37px;
}
.grid-content {
  text-align: center;
  height: 250px;
  line-height: 50px;
  padding-top: 50px;
}
.grid-content:hover {
  cursor: pointer;
  background: #f6f8f9;
  height: 250px;
}
.grid-content2 {
  position: relative;
  cursor: pointer;
}
.imgText2 {
  font-size: 19px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #ffffff;
  margin: 10px;
}
.hzgs {
  height: 23px;
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #475aff;
  line-height: 37px;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
</style>
